import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { crews } from "../../../AppUtilities";
import { IMG, NOIMG } from "../../../API/constant";
const noimg =
  "https://filestore.community.support.microsoft.com/api/images/0ce956b2-9787-4756-a580-299568810730?upload=true&fud_access=wJJIheezUklbAN2ppeDns8cDNpYs3nCYjgitr%2BfFBh2dqlqMuW7np3F6Utp%2FKMltnRRYFtVjOMO5tpbpW9UyRAwvLeec5emAPixgq9ta07Dgnp2aq5eJbnfd%2FU3qhn5498QChOTHl3NpYS7xR7zASsaF20jo4ICSz2XTm%2B3GDR4XitSm7nHRR843ku7uXQ4oF6innoBxMaSe9UfrAdMi7owFKjdP9m1UP2W5KAtfQLNmxTsxg1%2FabxtlYKkUkll1%2FwD9numjD9uefO%2F1LZdKqU7lXRnq8tosxJCH2FKfoSYFb317gElFQRoqXEkBQT8bBeiiddZjw1R%2BgtCZc%2FxhzrYBOqzi%2F0MZ6VSUvYe%2F5bIXsm4I5U%2FeuxWU77RbT72ZR1TfmkhuYTZyIKmkNpfkH3yIyIVfAD1igUVKh70PIVY%3D";
const Crew = ({ allData }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 4,
    },
    tabletlandscape: {
      breakpoint: { max: 767, min: 667 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items:2,
    },
  };
  return (
    <>
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        showDots={true}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        center={true}
      >
        {allData.length === 0 ? (
          <>No Data</>
        ) : (
          allData.crew.map((item, index) => (
            <div key={index}>
              <div class="item">
                <a href="#">
                  <img
                    src={item.image === null ? noimg : IMG + item.image}
                    alt=""
                    class="cast_img"
                  />
                  <div class="cast_content">
                    <p class="cast_text">
                      {item.name}
                      <br />
                      as {item.role}
                    </p>
                  </div>
                </a>
              </div>
            </div>
          ))
        )}
      </Carousel>
    </>
  );
};

export default Crew;
