import React, { useEffect } from "react";
import welogo from "../assets/images/welcome.png";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
const Welcome = () => {
  const navigete = useNavigate();
  useEffect(() => {
    // const timer = setTimeout(() => {
    //   navigete("/home");
    // }, 4000);
    // // ?**Cleanup function to clear the timeout if the component unmounts or dependencies change
    // return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="pb-2 text-center welcome">
        <img src={welogo} />

        <Link className="btn btn-secondary2 becomebtn" to="/home">
          Welcome
        </Link>
      </div>
    </>
  );
};

export default Welcome;
