import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../pages/Home/Home";
import Header from "../components/Header";
import Footer from "../components/Footer";
import MovieList from "../pages/Movie/MovieList";
import MovieDetails from "../pages/Movie/MovieDetails";
import Introdution from "../pages/Introdution/Introdution";
import Login from "../pages/Auth/Login";
import Signup from "../pages/Auth/Signup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Forgotpass from "../pages/Auth/Forgotpass";
import Creater from "../pages/Auth/Creater";
import Introductions from "../pages/Creator/Introduction";
import Myaccount from "../pages/Creator/Myaccount";
import * as API from "../API/Index";
import Serise from "../pages/Creator/Serise";
import Episode from "../pages/Creator/Episode";
import MovieUpload from "../pages/Creator/MovieUpload";
import EditProfile from "../pages/UserDashboard/EditProfile";
import MyMovieList from "../pages/Creator/MyMovieList";
import AddAward from "../pages/Creator/AddAward";
import AwardList from "../pages/Creator/AwardList";
import CreatorList from "../pages/Creator/CreatorList";
import "react-datepicker/dist/react-datepicker.css";
import BlogList from "../pages/Blog/BlogList";
import Faqs from "../pages/Faqs/Faqs";
import Contact from "../pages/Contact/Contact";
import About from "../pages/About/About";
import FeedbackStore from "../pages/Feedback/FeedbackStore";
import BlogDetails from "../pages/Blog/BlogDetails";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import Terms from "../pages/Terms/Terms";
import ForumList from "../pages/Forum/ForumList";
import Forumdetails from "../pages/Forum/Forumdetails";
import UserReview from "../pages/Feedback/UserReview";
import HelpCenter from "../pages/Contact/HelpCenter";
import Plans from "../pages/Plan/Plans";
import ProductList from "../pages/Shop/ProductList";
import AllVideoPlayer from "../pages/AllVideoPlayer";
import Welcome from "../pages/Welcome";
import Career from "../pages/Career/Career";

const AppRouter = () => {
  const [isUser, setIsuser] = useState(0);
  const [formData, setFormData] = useState([]);
  const [allCreator, setCreator] = useState([]);
  const [isLogin, setIsLogin] = useState(
    JSON.parse(localStorage.getItem("isLogin"))
  );

  const roouserDataGetById = async () => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const response = await API.getuserDataID(
        header,
        localStorage.getItem("_userId")
      );
      const creatoresponse = await API.creatorList();
      setCreator(creatoresponse.data.data);
      setFormData(response.data.data);
    } catch (error) {}
  };
  useEffect(() => {
    roouserDataGetById();
  }, []);
  return (
    <>
      <Router>
        <Header
          setIsuser={setIsuser}
          isLogin={isLogin}
          setIsLogin={setIsLogin}
        />
        <ToastContainer />
        {isLogin ? (
          <Routes>
            <Route path="/" element={<Welcome allCreator={allCreator} />} />
            <Route path="/home" element={<Home allCreator={allCreator} />} />
            <Route path="/movie-list" element={<MovieList />} />
            <Route path="/movie-details/:slug" element={<MovieDetails />} />
            <Route path="/creator/:slug" element={<Introdution />} />
            <Route
              path="/creator/all"
              element={<CreatorList allCreator={allCreator} />}
            />
            <Route
              path="/creator/sign-up"
              element={<Creater isUser={isUser} />}
            />
            <Route
              path="/creator/my-account"
              element={<Introductions data={formData} />}
            />
            <Route path="/forgot-password" element={<Forgotpass />} />
            <Route
              path="/dashboard"
              element={<Myaccount data={formData} setIsLogin={setIsLogin} />}
            />
            <Route
              path="/movie-upload"
              element={<MovieUpload data={formData} setIsLogin={setIsLogin} />}
            />
            <Route
              path="/movie-edit"
              element={<MovieUpload data={formData} setIsLogin={setIsLogin} />}
            />
            <Route
              path="/add-award"
              element={<AddAward data={formData} setIsLogin={setIsLogin} />}
            />
            <Route
              path="/award-list"
              element={<AwardList data={formData} setIsLogin={setIsLogin} />}
            />
            <Route
              path="/creator/movie-list"
              element={<MyMovieList data={formData} setIsLogin={setIsLogin} />}
            />
            <Route
              path="/set-profile"
              element={
                <EditProfile
                  data={formData}
                  roouserDataGetById={roouserDataGetById}
                  setIsLogin={setIsLogin}
                />
              }
            />
            <Route
              path="/series/episode"
              element={<Episode setIsLogin={setIsLogin} />}
            />
            <Route path="/blogs" element={<BlogList isUser={isUser} />} />
            <Route
              path="/blog-details/:slug"
              element={<BlogDetails isUser={isUser} />}
            />
            <Route path="/faqs" element={<Faqs isUser={isUser} />} />
            <Route path="/contact" element={<Contact isUser={isUser} />} />
            <Route path="/about" element={<About isUser={isUser} />} />
            <Route path="/forum" element={<ForumList isUser={isUser} />} />
            <Route
              path="/forum-details"
              element={<Forumdetails isUser={isUser} />}
            />
            <Route
              path="/user-review"
              element={<UserReview isUser={isUser} />}
            />
            <Route
              path="/help-center"
              element={<HelpCenter isUser={isUser} />}
            />
            <Route path="/plans" element={<Plans isUser={isUser} />} />
            <Route path="/products" element={<ProductList isUser={isUser} />} />
            <Route
              path="/feedback"
              element={<FeedbackStore isUser={isUser} />}
            />
            <Route
              path="/privacy-policy"
              element={<PrivacyPolicy isUser={isUser} />}
            />
            <Route
              path="/terms-and-conditions"
              element={<Terms isUser={isUser} />}
            />

<Route
              path="/career"
              element={<Career isUser={isUser} />}
            />
            <Route path="/watch" element={<AllVideoPlayer isUser={isUser} />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<Welcome allCreator={allCreator} />} />
            <Route path="/home" element={<Home allCreator={allCreator} />} />
            <Route path="/movie-list" element={<MovieList />} />
            <Route path="/movie-details/:slug" element={<MovieDetails />} />
            <Route path="/creator/:slug" element={<Introdution />} />
            <Route
              path="/creator/all"
              element={<CreatorList allCreator={allCreator} />}
            />

            <Route
              path="/creator/sign-up"
              element={<Creater isUser={isUser} />}
            />
            <Route
              path="/creator/my-account"
              element={<Introductions data={formData} />}
            />

            <Route
              path="/login"
              element={
                <Login
                  setFormData={setFormData}
                  roouserDataGetById={roouserDataGetById}
                  setIsLogin={setIsLogin}
                />
              }
            />
            <Route path="/sign-up" element={<Signup isUser={isUser} />} />
            <Route
              path="/creator/sign-up"
              element={<Creater isUser={isUser} />}
            />

            <Route path="/blogs" element={<BlogList isUser={isUser} />} />

            <Route
              path="/blog-details/:slug"
              element={<BlogDetails isUser={isUser} />}
            />

            <Route path="/faqs" element={<Faqs isUser={isUser} />} />

            <Route path="/contact" element={<Contact isUser={isUser} />} />

            <Route path="/about" element={<About isUser={isUser} />} />

            <Route path="/forum" element={<ForumList isUser={isUser} />} />

            <Route
              path="/forum-details"
              element={<Forumdetails isUser={isUser} />}
            />

            <Route
              path="/user-review"
              element={<UserReview isUser={isUser} />}
            />

            <Route
              path="/help-center"
              element={<HelpCenter isUser={isUser} />}
            />

            <Route path="/plans" element={<Plans isUser={isUser} />} />

            <Route path="/products" element={<ProductList isUser={isUser} />} />

            <Route
              path="/feedback"
              element={<FeedbackStore isUser={isUser} />}
            />

            <Route
              path="/privacy-policy"
              element={<PrivacyPolicy isUser={isUser} />}
            />

            <Route
              path="/terms-and-conditions"
              element={<Terms isUser={isUser} />}
            />

            <Route
              path="/career"
              element={<Career isUser={isUser} />}
            />

            <Route path="/forgot-password" element={<Forgotpass />} />
            <Route path="/watch" element={<AllVideoPlayer isUser={isUser} />} />
          </Routes>
        )}

        <Footer />
      </Router>
    </>
  );
};

export default AppRouter;
