import React from 'react';
import { careerPhoto } from '../../AppUtilities';

const Career = () => {
  return (
    <div>
      <section class="contentPage career innerpage">
        <div class="container-fluid">
            <div class="row justify-content-center align-items-center mb-5">
                <div class="col-sm-5">
                    <h2>The Best Work of Our Lives
                    </h2>
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima voluptatum quod repudiandae architecto eum nesciunt, quo vel maiores perspiciatis aperiam magnam blanditiis doloremque omnis rem, necessitatibus fugit nihil quam iusto.</p>
                    <span><a href="#" class="btn btn-info">READ ABOUT OUR CULTURE <i class="fa-solid fa-arrow-right"></i></a></span>
                </div>
                <div class="col-sm-5">
                    <img src={careerPhoto} alt="career" class="img-fluid" />
                </div>
            </div>

            <div class="row justify-content-center align-items-center flex-row-reverse mb-5">
                <div class="col-sm-6">
                    <h2>Streaming entertainment. Global original content. Product personalization.
                    </h2>
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima voluptatum quod repudiandae architecto eum nesciunt, quo vel maiores perspiciatis aperiam magnam blanditiis doloremque omnis rem, necessitatibus fugit nihil quam iusto.</p>
                    <span><a href="#" class="btn btn-info">See what's next <i class="fa-solid fa-arrow-right"></i></a></span>
                </div>
                <div class="col-sm-4">
                    <img src={careerPhoto} alt="career" class="img-fluid" />
                </div>
            </div>
        </div>
    </section>

    <section class="p-v-100 parallax">
        <div class="container-fluid">
            <div class="row justify-content-center ">
                <div class="col-md-6 col-sm-8">
                    <h2 class="text-center mb-5">Fill this form</h2>
                    <div class="row align-items-center">
                        <div class="col-md-6 mb-4">
                            <input type="text" class="form-control" id="" placeholder="Name"/>
                        </div>
                        <div class="col-md-6 mb-4">
                            <input type="text" class="form-control" id="" placeholder="Email"/>
                        </div>
                        <div class="col-md-6 mb-4">
                            <input type="text" class="form-control" id="" placeholder="Take Inquiries "/>
                        </div>
                        <div class="col-md-6 mb-4">
                            <select name="" id="" class="form-select form-control">
                                <option value="">All Positions</option>
                                <option value="">creator</option>
                                <option value="">production staff</option>
                                <option value="">actor</option>
                                <option value="">crew </option>
                            </select>
                        </div>
                        <div class="col-md-12 mb-4">
                            <label for="">Upload your file</label>
                            <div class="file-drop-area mt-2 mb-3">
                                <span class="choose-file-button">Choose files</span>
                                <span class="file-message">or drag and drop files here</span>
                                <input class="file-input" type="file" multiple/>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <textarea name="" id="" placeholder="Industry Experience" class="form-control mb-3"></textarea>
                        </div>
                        <div class="form-check col-sm-6">
                            <label class="form-check-label"><input class="form-check-input" type="checkbox" id="check1" name="option1" value="something"/>
                            I agree and Submit.</label>
                        </div>
                        <div class="col-sm-6 text-end">
                            <button class="btn btn-info">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </div>
  );
}

export default Career;
