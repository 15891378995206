import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { bannerMovies } from "../AppUtilities";
import { Link } from "react-router-dom";
import { IMG } from "../API/constant";

const HomeBannerSlide = ({ trandingMovie }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 4,
    },
    tabletlandscape: {
      breakpoint: { max: 767, min: 667 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 2,
    },
  };

  return (
    <>
      <div class="row">
        {trandingMovie === undefined
          ? ""
          : trandingMovie.map((item, index) => (
              <div class="col-6 col-sm-6 col-md-4 col-lg-2" key={index}>
                <div class="item text-center">
                  <Link to={`/movie-details/${item.slug}`}>
                    <img src={IMG + item.poster} alt="" className="img-fluid" />
                    <p className="mt-3 text-light">{item.name}</p>
                  </Link>
                </div>
              </div>
            ))}
      </div>
    </>
  );
};

export default HomeBannerSlide;
