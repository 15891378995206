import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProfileSection from "./ProfileSection";
import SideBar from "../UserDashboard/SideBar";
import * as API from "../../API/Index.js";
import { IMG } from "../../API/constant.js";
const MyMovieList = ({ data, setIsLogin }) => {
  const [movieList, setMovieList] = useState("");
  const userDataGetById = async () => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const reQobj = {
        user_id: localStorage.getItem("_userId"),
      };
      const response = await API.getMovieForCreator(reQobj, header);
      setMovieList(response.data.data);
      console.log("response", response);
    } catch (error) {}
  };

  useEffect(() => {
    userDataGetById();
  }, []);
  return (
    <>
      <ProfileSection formData={data} />
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="jj">
            <div class="tab_main editProfile">
              <div class="row justify-content-center g-0">
                <div class="col-sm-12 col-md-12 row">
                  <div className="col-md-3">
                    <SideBar formData={data} setIsLogin={setIsLogin} />
                  </div>
                  <div className="col-md-9">
                    <div className="tab-content">
                          <div className="d-flex flex-wrap justify-content-between mb-4 align-items-center">
                            <h4>Movie</h4>
                            <Link
                              className="btn btn-secondary2"
                              to="/movie-upload"
                            >
                              Add Movie
                            </Link>
                          </div>
                        <div className="table-responsive movieListTable">
                          <table class="table table-dark ">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Duration</th>
                                <th>Access </th>
                                <th>Release Date </th>
                                {/* <th>Thumbnail </th> */}
                                <th>Poster </th>
                                {/* <th>Status </th> */}
                                <th>Action </th>
                              </tr>
                            </thead>
                            <tbody>
                              {movieList === undefined ||
                              movieList.length === 0 ? (
                                <h5 className="d-block mt-4 text-center">
                                  Data Not Found
                                </h5>
                              ) : (
                                movieList.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>{item.duration}</td>
                                    <td>{item.access}</td>
                                    <td>{item.release_date}</td>

                                    <td>
                                      <img
                                        className="w-75"
                                        src={IMG + item.poster}
                                      />
                                    </td>

                                    <td>
                                      <Link
                                        state={{ id: item.id }}
                                        to="/movie-edit"
                                        className="btn btn-secondary2"
                                      >
                                        <i class="bi bi-pen"></i>
                                      </Link>
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyMovieList;
