import React, { useEffect, useRef, useState } from "react";
import * as API from "../../API/Index.js";
import { MESSAGE } from "../../schemas/Validation.js";
import { BeatLoader } from "react-spinners";
import MovieDetailsTable from "./MovieDetailsTable.js";
import { Link, useNavigate } from "react-router-dom";
import io from "socket.io-client";
import { SURL } from "../../API/constant.js";
import { toast } from "react-toastify";

import { CircularProgressbar } from "react-circular-progressbar";
const initialValues = {
  title: "",
  subtitle: "",
  video: "",
  description: "",
  cast: "",
  age_limit: "",
  thumbnail: "",
};

const VideoUpload = ({ setIsMove, moveiInsId }) => {
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(true);
  console.log("isUploading", isUploading);
  const [progress, setProgress] = useState({ movie: 0, trailer: 0 });
  const [uploadErrors, setUploadErrors] = useState({
    movie: false,
    trailer: false,
  });
  const [uploadStates, setUploadStates] = useState([]);
  const [isUpload, setIsUpload] = useState(false);
  const [isUploadmove, setIsUploadmove] = useState(false);
  const [isTalierpath, setIsTalierpath] = useState("");
  const [isMoviepath, setIsMoviepath] = useState("");
  const [isVideoDuration, setIsVideoDuration] = useState("");
  console.log("isVideoDuration", isVideoDuration);
  const successColor = "#28a745";
  const dangerColor = "#dc3545";

  const socket = io(SURL, {
    reconnectionDelayMax: 10000, // Maximum delay before a reconnection attempt (in milliseconds)
    timeout: 20000, // Socket connection timeout (in milliseconds)
    reconnectionAttempts: Infinity, // Number of reconnection attempts before giving up
  });

  console.log("socket", socket);

  // const socket = io({
  //   reconnectionDelayMax: 10000, // Maximum delay before a reconnection attempt (in milliseconds)
  //   timeout: 20000, // Socket connection timeout (in milliseconds)
  //   reconnectionAttempts: Infinity, // Number of reconnection attempts before giving up
  // });

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];

    const chunkSize = 5 * 1024 * 1024;
    const totalChunks = Math.ceil(file.size / chunkSize);
    const progressBar =
      type === "movie" ? "progressBarMovie" : "progressBarTrailer";
    if (type === "trailer") {
      setIsUpload(true);
    } else {
      getVideoDurationInSeconds(file);
      setIsUploadmove(true);
    }
    const uploadState = {
      progressBar,
      file,
      currentChunk: 0,
      totalChunks,
      uploadId: null,
      type,
      chunkSize,
    };

    setUploadStates((prevStates) => [...prevStates, uploadState]);

    // Reset error state and progress for the selected type
    setUploadErrors((prevErrors) => ({
      ...prevErrors,
      [type]: false,
    }));

    setProgress((prevProgress) => ({
      ...prevProgress,
      [type]: 0,
    }));

    startUpload(uploadState);
  };

  const startUpload = (uploadState) => {
    socket.emit("startUpload", {
      fileName: uploadState.file.name,
      type: uploadState.type,
    });

    socket.once("uploadStarted", (data) => {
      if (data.uploadId) {
        uploadState.uploadId = data.uploadId;
        uploadNextChunk(uploadState);
      }
    });

    socket.on("chunkUploaded", (data) => {
      if (data.uploadId === uploadState.uploadId) {
        uploadState.currentChunk++;

        const progressValue =
          (uploadState.currentChunk / uploadState.totalChunks) * 100;

        setProgress((prevProgress) => ({
          ...prevProgress,
          [uploadState.type]: progressValue,
        }));

        if (uploadState.currentChunk < uploadState.totalChunks) {
          uploadNextChunk(uploadState);
        } else {
          socket.emit("completeUpload", {
            uploadId: uploadState.uploadId,
            type: uploadState.type,
          });
        }
      }
    });

    socket.on("uploadCompleted", (data) => {
      if (data.uploadId === uploadState.uploadId) {
        setUploadErrors((prevErrors) => ({
          ...prevErrors,
          [uploadState.type]: false,
        }));

        if (data.type === "trailer") {
          setIsTalierpath(data.fileName);
          setIsUpload(false);
        } else {
          setIsMoviepath(data.fileName);
          setIsUploadmove(false);
        }
        toast.success(data.message);

        uploadState.currentChunk = 0; // Reset current chunk
      }
    });

    socket.on("uploadError", (data) => {
      if (data.uploadId === uploadState.uploadId) {
        toast.error("Error uploading");
        setIsUpload(false);
        setIsUploadmove(false);
        setUploadErrors((prevErrors) => ({
          ...prevErrors,
          [uploadState.type]: true,
        }));
        socket.emit("cleanupChunks", { uploadId: uploadState.uploadId });
        removeEventListeners();
      }
    });

    socket.on("disconnect", (reason) => {
      setUploadErrors((prevErrors) => ({
        ...prevErrors,
        [uploadState.type]: true,
      }));
      setIsUpload(false);
      setIsUploadmove(false);
      toast.error("Error uploading");
      console.error(`Disconnected from server: ${reason}`);
      removeEventListeners();
    });

    socket.on("reconnect", () => {
      console.log("Socket reconnected");
      uploadStates.forEach((uploadState) => {
        if (uploadState.uploadId) {
          console.log(`Resuming upload for uploadId: ${uploadState.uploadId}`);
          uploadNextChunk(uploadState);
        }
      });
    });
  };

  const getVideoDurationInSeconds = (file) => {
    const video = document.createElement("video");
    const objectURL = URL.createObjectURL(file);

    video.addEventListener("loadedmetadata", () => {
      const duration = video.duration;
      setIsVideoDuration(duration);
      console.log("Video duration:", duration);
    });

    video.src = objectURL;
    video.load();
  };

  const uploadNextChunk = (uploadState) => {
    if (socket.connected) {
      const start = uploadState.currentChunk * uploadState.chunkSize;
      const end = Math.min(
        start + uploadState.chunkSize,
        uploadState.file.size
      );
      const chunk = uploadState.file.slice(start, end);
      const reader = new FileReader();
      reader.onload = (e) => {
        socket.emit("uploadChunk", {
          chunk: e.target.result,
          uploadId: uploadState.uploadId,
        });
      };
      reader.readAsArrayBuffer(chunk);
    } else {
      console.warn("Socket not connected. Unable to upload chunk.");
    }
  };

  const removeEventListeners = () => {
    socket.off("chunkUploaded");
    socket.off("uploadCompleted");
    socket.off("uploadError");
    socket.off("disconnect");
  };

  const uploadVideo = async () => {
    const header = localStorage.getItem("_tokenCode");
    const reQObj = {
      step: "two",
      upload_trailer: isTalierpath,
      upload_movie: isMoviepath,
      id: moveiInsId,
      user_id: localStorage.getItem("_userId"),
      duration: isVideoDuration,
    };
    console.log("reQObj", reQObj);
    const response = await API.creatorVideoAdd(reQObj, header);
    console.log("response", response);
    if (response.data.success === 1) {
      navigate("/creator/movie-list");
      MESSAGE(response.data.message, 1);
      setIsMove(1);
    } else {
    }
  };

  useEffect(() => {
    return () => {
      removeEventListeners();
    };
  }, []);
  console.log("isMoviepath", isMoviepath);
  return (
    <>
      <div class="row row--form">
        <div class="col-12">
          <div class="row row--form justify-content-center">
            <div class="col-12 col-sm-6 col-md-8">
              <div
                class={
                  isUpload
                    ? "form__img videoupload disabledbutton"
                    : "form__img videoupload"
                }
              >
                <label for="form__img-uploadss">Upload Trailer</label>
                <form encType="multipart/form-data">
                  <input
                    id="form__img-uploadss"
                    name="poster"
                    onChange={(e) => handleFileChange(e, "trailer")}
                    type="file"
                  />
                </form>
              </div>
            </div>
            <div className="col-10 col-sm-6 col-md-4 mb-3">
              <div className="progressBar">
                <CircularProgressbar
                  value={progress.trailer}
                  text={`${progress.trailer.toFixed(0)}%`}
                  styles={{
                    path: {
                      stroke: uploadErrors.trailer ? dangerColor : successColor,
                    },
                    text: {
                      fill: uploadErrors.trailer ? dangerColor : successColor,
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div class="row row--form">
            <div class="col-12 col-sm-6 col-md-8">
              <div
                class={
                  isUploadmove
                    ? "form__img videoupload disabledbutton"
                    : "form__img videoupload"
                }
              >
                <label for="form__img-upload">Upload Movie</label>
                <form encType="multipart/form-data">
                  <input
                    id="form__img-upload"
                    name="postermovei"
                    onChange={(e) => handleFileChange(e, "movie")}
                    type="file"
                  />
                </form>
              </div>
            </div>
            <div className="col-10 col-sm-6 col-md-4 mb-3">
              <div className="progressBar">
                <CircularProgressbar
                  value={progress.movie}
                  text={`${progress.movie.toFixed(0)}%`}
                  styles={{
                    path: {
                      stroke: uploadErrors.movie ? dangerColor : successColor,
                    },
                    text: {
                      fill: uploadErrors.movie ? dangerColor : successColor,
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="row row--form">
            <div class="col-12">
              {isMoviepath === "" || isTalierpath === "" ? (
                ""
              ) : (
                <button
                  type="button"
                  onClick={uploadVideo}
                  class="btn btn btn-secondary2 mt-3"
                >
                  Publish
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoUpload;
