import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { commonVideo, videos } from "../AppUtilities";
import { videoPoster } from "../AppUtilities";
import { IMG, NOIMG } from "../API/constant";
import { Link } from "react-router-dom";

const NgSliders = ({ allCreator, allTrailer, movieWishListAdd }) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 667);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial value

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 667 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 667, min: 0 },
      items: 1,
      centerMode: false,
      
    },
  };

  return (
    <>
      <div className="custom-carousel-container">
        <h3 class="mb-3 site_heading text-center">
          Latest <span>Trailers</span>
        </h3>
        <Carousel
          responsive={responsive}
          //swipeable={true}
          // draggable={true}
          // ssr={true} // means to render carousel on server-side.
          infinite={true}
          showDots={true} // Hide dots
          arrows={true} // Hide arrows
          // autoPlay={true}
          autoPlaySpeed={3000}
          centerMode={!isMobile} // Disable centerMode on mobile
        >
          {allTrailer.map((item, index) => (
            <div key={index}>
              <div class="item">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
                  <video
                  autoPlay
                  muted
                  src=${item.trailer}
                  //  poster=${IMG + item.thumbnail}
                  class=" aboutVideo"
                  />,
              `,
                  }}
                ></div>
                <div class="videoContent">
                  <h3>{item.name}</h3>
                  <Link to={`/movie-details/${item.slug}`}>Watch Now</Link>
                </div>
                {/* <Link to={`/movie-details/${item.slug}`}>
                  <h3>{item.name}</h3>
                  <span></span>
                </Link> */}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default NgSliders;
